<template>
    <section class="display-flex mb-4 is-blue">
        <div @click="goBack">
            <b-icon pack="fas" icon="arrow-left" size="is-medium"/>
        </div>
        <span class="page-title">{{ routeNameHumanized }}</span>
    </section>
</template>

<script>
export default {
    props: {
        routeName: {
            type: String,
            default: null
        },
        routeNameHumanized: {
            type: String,
            default: ''
        }
    },
    methods: {
        goBack() {
            this.$emit('on-leave')
            if (this.routeName) {
                this.$router.push({ name: this.routeName });
                return
            }
            if (window.history.length) {
                this.$router.go(-1)
                return
            }
           this.$router.push({ name: 'home' });
        }
    }
};
</script>

<style lang="scss" scoped>
.page-title,
.icon {
    color: var(--primary);
    font-family: 'Inter', sans-serif;
}

.icon {
    height: 18px;
    width: auto;
}

.page-title {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}
</style>