export const ActivityCategories = [
    {
        index: 0,
        name: 'Tuk Tuk',
        icon: '🛺'
    },
    {
        index: 1,
        name: 'Museums',
        icon: '🏺'
    },
    {
        index: 2,
        name: 'History',
        icon: '📚'
    },
    {
        index: 3,
        name: 'Architecture',
        icon: '🏛️'
    },
    {
        index: 4,
        name: 'Sightseeing',
        icon: '🗽'
    },
    {
        index: 5,
        name: 'Culture & History',
        icon: '🎨'
    },
    {
        index: 6,
        name: 'Sunny weather',
        icon: '☀️'
    },
    {
        index: 7,
        name: 'Water sports',
        icon: '🏄‍♀️'
    },
    {
        index: 8,
        name: 'Wine tasting',
        icon: '🍷'
    },
    {
        index: 9,
        name: 'Nature',
        icon: '🌴'
    },
    {
        index: 10,
        name: 'Bicycle',
        icon: '🚲'
    },
    {
        index: 11,
        name: 'Walking Tours',
        icon: '🚶'
    },
    {
        index: 12,
        name: 'Boat Tours',
        icon: '⛵'
    },
    {
        index: 13,
        name: 'Day trip',
        icon: '🚐'
    },  
    {
        index: 14,
        name: 'Tickets',
        icon: '🎫'
    }, 
    {
        index: 15,
        name: 'Food & Drinks',
        icon: '🎫'
    }   ,
    {
        index: 16,
        name: 'Free',
        icon: '🎫'
    }    ,
    {
        index: 17,
        name: 'Live music',
        icon: '🎫'
    }   
]