<template>
  <section>
    <b-field :label="isOptionalField('Activity name')" class="pb-2">
      <b-input placeholder="p. ex. Lisbon City Tour" v-model.trim="title"
        validation-message="The tour title has to have at least 5 characters" required minlength="5"
        maxlenght="60"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Description')" class="pb-3">
      <b-input v-model.trim="description" placeholder="Describe the tour in a few words" minlength="10" maxlength="1500"
        type="textarea" validation-message="The tour description has to have between 10 and 1500 characters"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Categories')" class="categories mb-2">
      <b-checkbox-button v-model="categories" v-for="(category, index) in ActivityCategories" :key="index"
        :native-value="index" type="is-primary"> <span class="pr-2">{{ category?.icon }}</span>{{ category.name
        }}</b-checkbox-button>
    </b-field>

    <div class="columns field pb-2">
      <b-field class="column pr-1 is-6" :label="isOptionalField('Market Price (€)')">
        <b-input type="float" placeholder="0,00" v-model="marketPrice"></b-input>
      </b-field>

      <b-field class="column pr-1 is-6" :label="isOptionalField('Price (€)')">
        <b-input type="float" placeholder="0,00" v-model="price"></b-input>
      </b-field>


    </div>

    <div class="columns field pb-2">
      <b-field class="column pr-1 is-6" :label="isOptionalField('Private Price (€)')">
        <b-input type="float" placeholder="0,00" v-model="privatePrice"></b-input>
      </b-field>

      <b-field class="column pl-1 is-6" :label="isOptionalField('Guider payment (€)')">
        <b-input type="float" placeholder="0,00" v-model="guiderPayment"></b-input>
      </b-field>
    </div>


    <div class="columns field pb-2">

      <b-field class="column" :label="isOptionalField('Duration (in minutes)')">
        <b-input type="number" min="0" v-model="duration"
          validation-message="How long is the tour? The duration should ONLY be in MINUTES"></b-input>


      </b-field>
    </div>

  </section>
</template>

<script>
import { ActivityFormMixin } from './mixins/ActivityFormMixin'
import { ActivityCategories } from '../enums/ActivityCategories';
export default {
  mixins: [ActivityFormMixin],
  props: {
    activityInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      ActivityCategories,
      title: this.activityInfo.title,
      description: this.activityInfo.description,
      price: this.activityInfo.price,
      privatePrice: this.activityInfo.privatePrice,
      marketPrice: this.activityInfo.marketPrice,
      // reservationPrice: this.activityInfo.reservationPrice,
      guiderPayment: this.activityInfo?.guiderPayment,
      duration: this.activityInfo.duration,
      categories: this.activityInfo?.categories
    };
  },
  computed: {
    changeData() {
      const {
        title,
        description,
        categories,
        duration,
        price,
        marketPrice,
        privatePrice,
        //  reservationPrice,
        guiderPayment
      } = this;
      return {
        title,
        description,
        categories,
        duration,
        price,
        marketPrice,
        privatePrice,
        // reservationPrice,
        guiderPayment
      };
    },
  },
  watch: {
    changeData: {
      handler: function () {
        this.emitData();
      },
      deep: true,
    },
  },
  activated() {
    this.emitData();
  },

  methods: {
    emitData() {
      if (this.title.length >= 5 && this.description.length >= 10 && this.price != null && this.privatePrice != null) {
        this.$emit("dataChanged", {
          title: this.title.charAt(0).toUpperCase() + this.title.slice(1),
          description: this.description,
          categories: this.categories,
          duration: this.duration,
          price: Number(this.price),
          privatePrice: Number(this.privatePrice),
          marketPrice: Number(this.marketPrice),
          //  reservationPrice: Number(this.reservationPrice),
          guiderPayment: Number(this.guiderPayment)
        });
      } else {
        this.$emit("dataChanged", "invalid");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  padding: 0;
}

::v-deep {
  .help.is-invisible {
    display: none;
  }

  .columns {
    margin-left: 0;
    margin-right: 0;
    align-items: flex-start;
  }

  .categories {
    .field.has-addons {
      display: grid;
      grid-template-columns: repeat(2, minmax(max-content, 1fr));
      margin-bottom: 2rem;
    }
  }
}
</style>
