import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { db } from "../firebase";
import store from "../store";
import { /* UsersMutations */ UsersStates } from "../store/Storetypes";

import CreateTour from "../components/CreateTour"
import CreateEvent from "../components/CreateEvent"
import CreateTicket from "../components/CreateTicket"
import Dashboard from "../Guiders/Dashboard"
import SchedulePlanner from "../Guiders/SchedulePlanner"
import CreateGroup from "../Guiders/CreateGroup"
import Settings from "../Settings/Settings"
import GeneralInfo from "../Settings/GeneralInfo"
import ChangePassword from "../Settings/ChangePassword"
// import NotificationPage from "../Guiders/NotificationPage"
import TicketScanner from "../components/TicketScanner"
import CompanyDashboard from '../Company/Dashboard';
import AddEmployee from '../Company/AddEmployee';
import EmployeesPage from '../Company/EmployeesPage';
import ManageEmployee from '../Company/ManageEmployee';
import AffiliateProgram from '@/Admin/AffiliateProgram'
import AdminDashboard from '@/Admin/Dashboard'
import ManageGuiders from '@/Admin/ManageGuiders'
import ManagePayments from '@/Admin/ManagePayments'
import UpcomingActivitiesPage from '@/Admin/UpcomingActivitiesPage'
import GuiderDetails from '@/Admin/GuiderDetails'
import CompanyProfile from '@/Company/CompanyProfile'
import AffiliatesPage from '@/components/AffiliatesPage'
import AffiliateHub from '@/components/AffiliateHub'
import RegistrationForm from '@/Company/RegistrationForm'
import StripeRefresh from '@/components/StripeRefresh'
import FeedbackForm from '@/Company/FeedbackForm.vue'


export const routes = [
  {
    path: "/blog/best-things-to-see-and-do-in-sintra-in-a-day",
    name: "guide-sintra",
    component: () => import(/* webpackChunkName: "login" */"../Blog/sintra"),
  },
  {
    path: "/blog/10-best-things-to-do-in-lisbon-in-2024",
    name: "10-best-things-to-do-in-lisbon-in-2024",
    component: () => import(/* webpackChunkName: "login" */"../Blog/lisbon"),
  },
  {
    path: "/blog/your-guide-to-santos-populares-portugal's-beloved-june-celebrations",
    name: "your-guide-to-santos-populares-portugal's-beloved-june-celebrations",
    component: () => import(/* webpackChunkName: "login" */"../Blog/santospopulares"),
  },
  
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */"../components/Login"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/concierge",
    name: "concierge",
    component: () => import(/* webpackChunkName: "concierge" */"../Concierge"),
  },
  {
    path: "/multi-day-tours/lisbon-and-porto-7-days",
    name: "concierge",
    component: () => import(/* webpackChunkName: "concierge" */"../MultiDayTours/PageContainer"),
  },
  {
    path: "/concierge/quote",
    name: "quote",
    component: () => import(/* webpackChunkName: "login" */"../components/QuoteForm"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/concierge/quote/success",
    name: 'quote-request-sent',
    component: () => import(/* webpackChunkName: "quote-request-sent" */"../components/QuoteRequestSent"),
    
  },
  {
    path: "/unlock",
    name: "unlock",
    component: () => import(/* webpackChunkName: "unlock" */"../Guiders/RegistrationGuard"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */"../components/Register"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/guider/register",
    name: "register-guider",
    component: () => import(/* webpackChunkName: "register" */"../components/Register"),
    meta: {
      title: 'Guiders.pt | Register',
      metaTags: [
        {
          name: 'description',
          content: 'Become a guider.'
        },
        {
          property: 'og:description',
          content: 'Become a guider.'
        }
      ]
    },
  },
  {
    path: "/tours/full-day-tours",
    name: "full-day-tours",
    component: () => import(/* webpackChunkName: "full-day-tours" */"../ToursByCategory/FullDayTours"),
    meta: { transition: 'fade' },
  },
  {
    path: "/tours/tuk-tuk-tours",
    name: "tuk-tuk-tours",
    component: () => import(/* webpackChunkName: "tuk-tuk-tours" */"../ToursByCategory/TukTukTours"),
    meta: { transition: 'fade' },
  },
  {
    path: "/tours/cultural-tours",
    name: "cultural-tours",
    component: () => import(/* webpackChunkName: "cultural" */"../ToursByCategory/CulturalTours"),
    meta: { transition: 'fade' },
  },
  {
    path: "/tours/portugal-architecture",
    name: "portugal-architecture",
    component: () => import(/* webpackChunkName: "portugal-architecture" */"../ToursByCategory/Architecture"),
    meta: { transition: 'fade' },
  },
  {
    path: "/tours/historical-tours",
    name: "historical-tours",
    component: () => import(/* webpackChunkName: "historical-tours" */"../ToursByCategory/HistoricalTours"),
    meta: { transition: 'fade' },
  },
  {
    path: "/:companyId/guider/register",
    name: "register-employee",
    component: () => import("../components/Register"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/admin/activities",
    name: "manage-activities",
    component: () => import("../Admin/ManageActivities"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    },
  },
  {
    path: "/admin/payment-history",
    name: "admin-payment-history",
    component: () => import("../Admin/PaymentHistory"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../Settings/Settings"),
    meta: {
       requiresAuth: true,
       title: 'Guiders.pt | Settings',
     }, 
  },
  /* {
    path: "/home",
    name: "home",
    component: () => import("../components/HomePage"),
    meta: {
      title: 'Guiders.pt | Shared tours and activities',
      metaTags: [
        {
          name: 'description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        },
        {
          property: 'og:description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        }
      ]
    }
  }, */
  {
    path: "/home",
    name: "home",
    component: () => import("../components/HomePage"),
    meta: {
      title: 'Guiders.pt | Shared tours and activities',
      metaTags: [
        {
          name: 'description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        },
        {
          property: 'og:description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        }
      ]
    }
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../components/Gallery"),
    meta: {
      title: 'Guiders.pt | Gallery',
      metaTags: [
        {
          name: 'description',
          content: 'Check out our previous tours and activities.'
        },
        {
          property: 'og:description',
          content: 'Check out our previous tours and activities.'
        }
      ]
    }
  },
  {
    path: "/",
    component: () => import("../components/HomePage"),
    meta: {
      title: 'Guiders.pt | Shared tours and activities',
      metaTags: [
        {
          name: 'description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        },
        {
          property: 'og:description',
          content: 'Discover Lisbon with shared and private tours and activities.'
        }
      ]
    }
  },
  {
    path: "/discover",
    name: "discover",
    component: () => import("../components/SearchResults"),
  },
  {
    path: "/booking-success",
    name: "success",
    component: () => import("../components/BookingSuccess"),
  },
  {
    path: "/settings/basic-information",
    name: "settings-basic-information",
    component: () => import("../Settings/GeneralInfo"),
    /* meta: {
      requiresAuth: true,
      isGuider: true,
    }, */
  },
  {
    path: "/guide-settings",
    name: "guide-settings",
    component: () => import("../Settings/Settings"),
   meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
  },
  {
    path: "/guider",
    name: "guider",
    component: () => import("../Guiders/Guider"),
     meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
    children: [
      {
        path: 'dashboard',
        name: 'guider-dashboard',
        component: Dashboard,
        /* meta: {
      requiresAuth: true,
      isGuider: true,
      */
    },
      
      {
        path: 'upcoming',
        name: 'guider-upcoming-activities',
        component: UpcomingActivitiesPage,
       meta: {
      requiresAuth: true,
      isGuider: true,
    },
      },
      {
        path: "activity/:activityId/group/:activityGroupId/start",
        name: "start-activity",
        component: CreateGroup,
         meta: {
          requiresAuth: true,
          isGuider: true,
        },
      },
      {
        path: "settings",
        name: "guider-settings",
        component: Settings,
        meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
      {
        path: "settings/basic-information",
        name: "guider-basic-information",
        component: GeneralInfo,
        meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
      {
        path: "settings/change-password",
        name: "guider-change-password",
        component: ChangePassword,
        meta: {
          requiresAuth: true,
          isGuider: true,
        },
      },
      /* {
         path: "notification/:activityGroupId",
         name: "activity-notification",
         component: NotificationPage,
          meta: {
           requiresAuth: true,
           isGuider: true,
         }, 
       },*/
      {
        path: "/scan-ticket",
        name: "scan-ticket",
        component: TicketScanner,
         meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
    ],
  },
  {
    path: "/guider/login",
    name: "signin-guider",
    component: () => import("../components/Login"),
    /* meta: {
      requiresAuth: true,
      isGuider: true,
    }, */
    meta: {
      requiresUnauth: true,
      title: 'Guiders.pt | Login',
      metaTags: [
        {
          name: 'description',
          content: 'Login to your guider account.'
        },
        {
          property: 'og:description',
          content: 'Login to your guider account.'
        }
      ]
    },
  },
  {
    path: "/guider/stripe-dashboard",
    name: "stripe-dashboard",
    component: () => import("../Guiders/StripeDashboard"),
    /* meta: {
      requiresAuth: true,
      isGuider: true,
    }, */
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../Company/Company"),

     meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
    children: [
      {
        path: 'dashboard',
        name: 'company-dashboard',
        component: CompanyDashboard,
         meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
      },
    {
      name: 'schedule',
      path: "schedule",
      component: SchedulePlanner,
      meta: {
    requiresAuth: true,
    isGuider: true,
  },
    },

      {
        path: 'upcoming',
        name: 'company-upcoming-activities',
        component: UpcomingActivitiesPage,
         meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
      },
      {
        path: 'employees',
        name: 'employees',
        component: EmployeesPage,
         meta: {
      requiresAuth: true,
      isGuider: true,
    },
      },
      {
        path: 'add-employee',
        name: 'add-employee',
        component: AddEmployee,
       meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
      },
      {
        path: '/:employeeId/manage-employee',
        name: 'manage-employee',
        component: ManageEmployee,
         meta: {
      requiresAuth: true,
      isGuider: true,
    }, 
      },

      {
        path: "settings",
        name: "company-settings",
        component: Settings,
         meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
      {
        path: "settings/basic-information",
        name: "company-basic-information",
        component: GeneralInfo,
        meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
      {
        path: "settings/profile",
        name: "company-profile",
        component: CompanyProfile,
        meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
      {
        path: "settings/change-password",
        name: "company-change-password",
        component: ChangePassword,
       meta: {
          requiresAuth: true,
          isGuider: true,
        }, 
      },
    ],
  },
  {
    path: "/:activityGroup/details",
    name: "activity-group-details",
    component: () => import("../Admin/ActivityGroupDetails"),
     meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },

  {
    path: "/admin/affiliates",
    name: "affiliates-program",
    component: AffiliateProgram,
     meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: AdminDashboard,
     meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/guiders",
    name: "manage-guiders",
    component: ManageGuiders,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/payments",
    name: "manage-payments",
    component: ManagePayments,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/guiders/:guiderUid",
    name: "guider-details",
    component: GuiderDetails,
     meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/upcoming",
    name: "upcoming-activities",
    component: UpcomingActivitiesPage,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    }, 
  },
  {
    path: "/admin/activities/edit/:id",
    name: "edit-activity",
    component: () => import("../components/CreateTour"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

    beforeRouteLeave: (to, from, next) => {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }

  },
  {
    path: "/admin/activities/create",
    name: "add-activity",
    component: CreateTour,
     meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

  },
  {
    path: "/admin/event/edit/:id",
    name: "edit-event",
    component: () => import("../components/CreateEvent"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

    beforeRouteLeave: (to, from, next) => {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }

  },
  {
    path: "/admin/event/create",
    name: "create-event",
    component: CreateEvent,
     meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

  },
  {
    path: "/admin/ticket/create",
    name: "create-ticket",
    component: CreateTicket,
     meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

  },
  {
    path: "/admin/ticket/edit/:id",
    name: "edit-ticket",
    component: () => import("../components/CreateTicket"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }, 

    beforeRouteLeave: (to, from, next) => {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }

  },
  {
    path: "/details/:id/:title?",
    name: "details",
    component: () => import("../components/TourPage"),
  },
  {
    path: "/event/:eventId/details/:id/:title?",
    name: "event-details",
    component: () => import("../components/TourPage"),
  },
  {
    path: "/frequently-asked-questions",
    name: "questions",
    component: () => import("../components/FrequentlyAskedQuestions.vue"),
    meta: {
      title: 'Guiders.pt | Frequently asked questions',
      metaTags: [
        {
          name: 'description',
          content: 'Frequently asked questions.'
        },
        {
          property: 'og:description',
          content: 'Frequently asked questions.'
        }
      ]
    },
  },
  {
    path: "/affiliates",
    name: "affiliates",
    component: AffiliatesPage,
    meta: {
      title: 'Guiders.pt | Become an Affiliate',
      metaTags: [
        {
          name: 'description',
          content: 'Become an affiliate and start earning money by recommending us to your audience.'
        },
        {
          property: 'og:description',
          content: 'Become an affiliate and start earning money by recommending us to your audience.'
        }
      ]
    },
  },
  {
    path: "/dashboard/affiliates",
    name: "affiliate-dashboard",
    component: AffiliateHub,
    meta: {
      title: 'Guiders.pt | Affiliate Hub'
    },
  },
  {
    path: "/affiliate/:accountId",
    name: "affiliateStripeRefresh",
    component: StripeRefresh
  },
  {
    path: "/registration",
    name: "registration",
    component: RegistrationForm,
    meta: {
      title: 'Guiders.pt | Become a guider',
      metaTags: [
        {
          name: 'description',
          content: 'Are you a tour operator? Work with us!'
        },
        {
          property: 'og:description',
          content: 'Are you a tour operator? Work with us!'
        }
      ]
    },
  },
  {
    path: "/feedback",
    name: "feedback",
    component: FeedbackForm,
    meta: {
      title: 'Feedback Form | Guiders',
      metaTags: [
        {
          name: 'description',
          content: 'Share your feedback on our personalized travel services in Portugal. Help us improve our private guided tours, transport services, and custom itineraries.'
        },
        {
          property: 'og:description',
          content: 'Share your feedback on our personalized travel services in Portugal. Help us improve our private guided tours, transport services, and custom itineraries.'
        }
      ]
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// TODO: SIMPLIFY THIS SHIT
router.beforeEach((to, from, next) => {

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();



  const isAuthenticated = db.auth().currentUser;
  if (to.meta.requiresAuth && !isAuthenticated) {
    // not authenticated
    next("/login");
  } else if (to.meta.requiresUnauth && isAuthenticated) {
    // doesn't let user go to login while authenticated
    next("/home");
  } else {
    if (to.meta.isGuider) {
     if (store.state.users[UsersStates.IS_COMPANY] || store.state.users[UsersStates.IS_EMPLOYEE]){
        next();
      } else {
        next("/home");
      }
    } else if (to.meta.isAdmin) {
      if (store.state.users[UsersStates.IS_ADMIN]){
        next();
      } else {
        next("/home");
      }
    } else {
      next();
    }
  }
});

export default router;
