<template>
  <div>
    <!-- <div class="container-fixed-top">
      <div class="is-flex is-justify-content-end">
        <p class="mr-4">support@guiders.pt</p>
        <p class="mr-4">(+351) 920 043 375</p>
      </div>
    </div> -->
    <b-navbar fixed-top close-on-click :active.sync="isMenuActive">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: logoPath }">
          <img src="../../assets/logo_new.svg" />
        </b-navbar-item>
      </template>
      <!--  <template #start>
        <b-navbar-item class="is-hidden-touch">
          <b-field>
            <b-input placeholder="Search" type="search" icon="search" v-model="search">
            </b-input>
          </b-field>
        </b-navbar-item>
      </template>
 -->
      <template #end>
        <b-navbar-item tag="div" v-if="!loggedIn">
          <!--  <div class="buttons">
            <router-link  to="/register">
              Sign up
            </router-link> -->

          <router-link :to="{ name: 'register-guider' }">
            <!-- <div class="mb-3 is-flex is-justify-content-space-between  action-cnt">
              <p class="title">Join our guiders team</p>
              <b-icon icon="arrow-right"></b-icon>
                <span class="description">Partner with us and start making money.</span>
           
            </div> -->
          </router-link>
          <b-button
            class="item-mobile mb-1 mr-3"
            size="is-medium"
            type="is-primary"
            tag="router-link"
            :to="{ path: '/login' }"
            outlined
          >
            Supplier Login
          </b-button>
          <div
            class="item-mobile mb-1 button is-medium is-primary"
            @click="isQuoteModalActive = true"
          >
            Request a quote
          </div>

          <!-- <router-link class="item-mobile mb-1" to="/gallery">
          Gallery
          </router-link>
 -->

          <!--  <router-link class="item-mobile" :to="{ name: 'affiliates' }">Become an affiliate</router-link>
 -->
          <!-- /div> -->
        </b-navbar-item>

        <div v-if="loggedIn" class="is-hidden-touch">
          <b-dropdown
            position="is-bottom-left"
            aria-role="list"
            trap-focus
            :triggers="['hover']"
          >
            <template #trigger>
              <div class="item-mobile">
                <img class="user-img" :src="user?.photoURL || ''" />
                <span>{{ name }}</span>
              </div>
            </template>

            <AdminMenu
              v-if="isAdmin"
              @toggleVisibility="toggleVisibility"
              @signOut="signOut"
            />
            <EmployeeMenu
              v-else-if="isEmployee"
              @toggleVisibility="toggleVisibility"
              @signOut="signOut"
            />
            <CompanyMenu
              v-else-if="isCompany"
              @toggleVisibility="toggleVisibility"
              @signOut="signOut"
            />
          </b-dropdown>
        </div>

        <div v-if="loggedIn" class="is-hidden-desktop">
          <div class="columns is-mobile mb-2">
            <!-- 
            <div class="column is-2">
              <img class="user-img" :src="user?.photoURL || ''" />
            </div>
 -->
            <div class="column">
              <b
                ><span class="user-name is-size-5">Hello, </span
                >{{ user?.name || user?.displayName }}</b
              >
              <p>{{ user?.email }}</p>
            </div>
          </div>
          <AdminMenu
            v-if="isAdmin"
            @toggleVisibility="toggleVisibility"
            @signOut="signOut"
          />
          <EmployeeMenu
            v-else-if="isEmployee"
            @toggleVisibility="toggleVisibility"
            @signOut="signOut"
          />
          <CompanyMenu
            v-else-if="isCompany"
            @toggleVisibility="toggleVisibility"
            @signOut="signOut"
          />
        </div>
      </template>
    </b-navbar>
    <b-modal
      :full-screen="isMobileView"
      v-model="isQuoteModalActive"
      :width="660"
      scroll="keep"
    >
      <div class="card">
        <QuoteForm />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { db } from "../../firebase";
import { UserActions, UsersStates } from "../../store/Storetypes";
import AdminMenu from "../../Admin/AdminMenu.vue";
import CompanyMenu from "../../Company/CompanyMenu.vue";
import EmployeeMenu from "@/Company/EmployeeMenu";
import { HelpersMixin } from "../../mixins";
import QuoteForm from "../../components/QuoteForm.vue";

export default {
  mixins: [HelpersMixin],
  components: {
    AdminMenu,
    CompanyMenu,
    EmployeeMenu,
    QuoteForm,
  },
  data() {
    return {
      loggedIn: false,
      showSearch: true,
      search: "",
      isMenuActive: false,
      isQuoteModalActive: false,
    };
  },
  props: ["isSignedIn"],
  computed: {
    ...mapState({
      isUserAGuider: (state) => state.users[UsersStates.IS_USER_A_GUIDER],
      isEmployee: (state) => state.users[UsersStates.IS_EMPLOYEE],
      isCompany: (state) => state.users[UsersStates.IS_COMPANY],
      isAdmin: (state) => state.users[UsersStates.IS_ADMIN],
      user: (state) => state.users[UsersStates.USER],
    }),
    name() {
      return this.user?.displayName || "";
    },
    logoPath() {
      if (this.isUserAGuider && this.isEmployee)
        return "guider-upcoming-activities";
      if (this.isUserAGuider && this.isCompany)
        return "company-upcoming-activities";
      return "home";
    },
  },
  created() {
    db.auth().onAuthStateChanged((user) => {
      if (!this.user?.uid) {
        this.loggedIn = false;
        return;
      }
      this.loggedIn = !!user;
    });
  },
  watch: {
    search(value) {
      this.$router.push({ name: "discover", query: { search: value } });
    },
    user: {
      handler: function () {
        if (!this.user?.uid) {
          this.loggedIn = false;
          return;
        }
        this.loggedIn = true;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      signOut: UserActions.SIGN_OUT,
    }),
    toggleVisibility(isVisible) {
      this.isMenuActive = isVisible;
    },
    updateMode() {
      this.mode = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fixed-top {
  background-color: #0e3d4d;
  color: white;
  top: 0;
  position: fixed;
  width: -webkit-fill-available;
  font-weight: 500;
  margin-bottom: 1rem;
  z-index: 100;
  justify-content: flex-end;
  padding: 4px 20px;
}

.button.is-medium {
  font-size: 0.9rem;
  font-weight: 300;
  border-radius: 0;
  padding: 22px;
}

.icon--sm {
  height: 1rem;
}
.navbar.is-fixed-top {
  // border-bottom: 1px solid #DBDFE7;
 // top: 28px;
 top:0;
  padding: 4px 0px;
  color: #0e3d4d;
  border-bottom: 1px solid #ededed;
}
.control.has-icons-left .input {
  width: 24rem;
}

.user-name {
  color: #446df6;
  font-weight: 600;
}

.title,
.description {
  font-family: "Inter", sans-serif;
}

.title {
  font-weight: 600;
  font-size: 1.1rem;
}

.description {
  font-size: 1rem;
  display: block;
  line-height: 16px;
}

.action-cnt {
  padding: 1rem;
  border-radius: 6px;
  background-color: #0e3d4d;
  bottom: 7px;
  position: fixed;
  margin-right: 1rem;
  width: -webkit-fill-available;

  .title,
  .icon {
    color: white;
    margin-bottom: 0;
  }
}

.navbar-item,
.navbar-link {
  padding: 0;
}

.navbar-item img {
  max-height: 2.2rem;
}

.navbar-item {
  .user-img {
    height: 2rem;
    width: 2rem;
    object-fit: cover;
    margin-right: 14px;
    border-radius: 100px;
  }
}

.user-img {
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  margin-right: 14px;
  border-radius: 100px;
}

.user-options {
  margin-left: 36px;
  margin-top: 8px;
}

.item-mobile {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.image-container img {
  height: 32px;
}

.navbar-item img,
.item-mobile img {
  margin-right: 8px;
}

.button.is-text {
  background-color: transparent;
  border-color: transparent;
  color: #4a4a4a;
  text-decoration: none;
}

.navbar-burger {
  color: #000000;
}

.navbar-item,
.navbar-link {
  font-size: 18px;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: transparent;
}

.row {
  display: inline-flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .navbar {
    margin-bottom: 0px;
  }

  .navbar-mobile {
    visibility: visible;
    display: inline-flex;
  }

  .user-img {
    height: 2.5rem;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0;
    border-radius: 155px;
    border: 1px solid black;
    padding: 2px;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-mobile {
    visibility: hidden;
    display: none;
  }

  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    display: flex;
    align-items: center;
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    color: #0e3d4d;
  }
}

.input {
  margin-bottom: 0px !important;
}

.navbar-mobile {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0px 0px 32px 0px;
  top: 0;
  position: fixed;
  left: 0;
  background: white;
  z-index: 40;
  padding: 24px;
}

.navbar-mobile span {
  margin-left: 12px;
  color: #312960c4;
}

span {
  color: #0e3d4d;
}

::v-deep {
  .dropdown-content {
    padding: 0.5rem;
    width: 30rem;
  }
}
</style>
